<template xmlns="http://www.w3.org/1999/html">
  <main class="privacy_page">
    <PageDescriptionComponent :data="description" />
    <div class="privacy">
     <h2> 1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
    <br/>  Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ООО «Стройтек» (далее – Оператор).

      <br/> 1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.

      <br/>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://gachalav.ru.

    <h2>2. ОСНОВНЫЕ ПОНЯТИЯ, ИСПОЛЬЗУЕМЫЕ В ПОЛИТИКЕ</h2>
      <br/> 2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;

      <br/>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);

      <br/> 2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://gachalav.ru;

      <br/> 2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;

      <br/> 2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;

      <br/>2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;

      <br/>2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;

      <br/> 2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://gachalav.ru;

      <br/>2.9. Пользователь – любой посетитель веб-сайта https://gachalav.ru;

      <br/> 2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;

      <br/> 2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;

      <br/>2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;

      <br/>2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.

      <h2>3. ОПЕРАТОР МОЖЕТ ОБРАБАТЫВАТЬ СЛЕДУЮЩИЕ ПЕРСОНАЛЬНЫЕ ДАННЫЕ ПОЛЬЗОВАТЕЛЯ</h2>
      <br/> 3.1. Фамилия, имя, отчество;

      <br/> 3.2. Электронный адрес;

      <br/> 3.3. Номера телефонов;

      <br/> 3.4. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).

      <br/> 3.5. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.

     <h2> 4. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <br/>4.1. Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем; заключение, исполнение и прекращение гражданско-правовых договоров; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте; информирование Пользователя посредством отправки СМС-сообщений.

      <br/> 4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a :href="'mailto:' + feedback.email " >{{feedback.email}}</a> с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».

      <br/> 4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.

      <h2>5. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <br/> 5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте https://gachalav.ru. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.

      <br/> 5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).

      <h2>6. ПОРЯДОК СБОРА, ХРАНЕНИЯ, ПЕРЕДАЧИ И ДРУГИХ ВИДОВ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <br/> Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.

      <br/>6.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.

      <br/>6.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.
      <a :href="'mailto:' + feedback.email " >{{feedback.email}}</a>  с пометкой «Актуализация персональных данных».

      <br/>6.4. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a :href="'mailto:' + feedback.email " >{{feedback.email}}</a>  с пометкой «Отзыв согласия на обработку персональных данных».

      <h2>7. ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <br/>7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.

      <br/>7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.

     <h2> 8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
      <br/> 8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a :href="'mailto:' + feedback.email " >{{feedback.email}}</a> .

      <br/> 8.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
   </div>
  </main>
</template>

<script>
import PageDescriptionComponent from "components/PageDescriptionComponent.vue";
import PORTAL_PAGE from "../../graphql/queries/portal_page.graphql";

export default {
  metaInfo: {
    title: "Политика конфиденциальности",
    titleTemplate: "%s | Gachalav Group",
    meta: [
      {
        vmid: "keywords",
        name: "keywords",
        content: "",
      },
      {
        vmid: "description",
        name: "description",
        content: "",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: "",
      },
    ],
  },
  components: { PageDescriptionComponent },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: PORTAL_PAGE,
        variables: {
          first: 12,
        },
      })
      .then(({ data }) => {
        store.state.objects_header = data.header;
        store.state.objects_footer = data.footer;
        store.state.socials = data.socials;
        store.state.phone = data.feedback;
      });
  },
  computed:{
    feedback() {
      return this.$store.state.phone;
    },
  },

  data() {
    return {
      description: {
        title: " Политика конфиденциальности ",
      },
    };
  },
};
</script>

<style lang="stylus">
.privacy_page{
  .page_description__title{
    +below(1024px){
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .privacy {
    padding: 162px 400px 0 400px;
    font-weight: 400;
    font-size: 1em;
    line-height: 27px;
    color: var(--dark);
    +below(1600px) {
      padding: 162px 200px 0 200px;
    }
    +below(1024px) {
      padding 30px 20px
      font-size: 0.8em;
      line-height: 26px;
    }
    h2 {
      font-weight: 700;
      font-size: 1.3em;
      line-height: 41px;
      color: var(--dark);
      margin-top 10px
      +below(1024px) {
        font-size: 1.2em;
        line-height: 34px;
      }
    }
    a{
      color var(--main)
    }
  }

}
</style>
